import React from 'react'
import { css } from '@emotion/core'
import { steamAnimation } from '../../utils/steamAnimation'

const DevelopmentExtended = ({ ...rest }) => {
  return (
    <svg
      {...rest}
      css={css`
        ${steamAnimation(['fume01', 'fume02', 'fume03'])}
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="852"
      height="373.968"
      viewBox="0 0 852 373.968"
    >
      <g id="development_extended" transform="translate(-902 -640.032)">
        <rect
          id="Rectangle_180"
          data-name="Rectangle 180"
          width="852"
          height="68"
          rx="34"
          transform="translate(902 946)"
          fill="#fafafc"
        />
        <g
          id="Group_174"
          data-name="Group 174"
          transform="translate(1338.328 640.032)"
        >
          <path
            id="Path_1037"
            data-name="Path 1037"
            d="M10.134,0H141.869C147.466,0,152,4.81,152,10.743l-11.259,167.11c0,5.933-4.537,10.743-10.133,10.743H21.393c-5.6,0-10.134-4.81-10.134-10.743L0,10.743C0,4.81,4.537,0,10.134,0Z"
            transform="translate(23.645 0)"
            fill="#464f72"
          />
          <path
            id="Union_16"
            data-name="Union 16"
            d="M6.194,126.106a6.193,6.193,0,1,1,0-12.386H59.676V0H75.44V113.72h53.482a6.193,6.193,0,1,1,0,12.386Z"
            transform="translate(31.525 221.811)"
            fill="#898888"
          />
          <rect
            id="Rectangle_170"
            data-name="Rectangle 170"
            width="152.003"
            height="36.03"
            rx="9"
            transform="translate(23.645 199.292)"
            fill="#464f72"
          />
          <rect
            id="Rectangle_174"
            data-name="Rectangle 174"
            width="38.282"
            height="25.897"
            rx="12.948"
            transform="translate(0 128.358)"
            fill="#464f72"
          />
          <rect
            id="Rectangle_175"
            data-name="Rectangle 175"
            width="38.282"
            height="25.897"
            rx="12.948"
            transform="translate(161.01 128.358)"
            fill="#3d4564"
          />
          <rect
            id="Rectangle_173"
            data-name="Rectangle 173"
            width="101.335"
            height="11.259"
            transform="translate(48.416 188.033)"
            fill="#898888"
          />
        </g>
        <g id="Group_177" data-name="Group 177" transform="translate(165 -58)">
          <path
            id="Path_111"
            data-name="Path 111"
            d="M4.393-.368h89a4,4,0,0,1,4,4v57a4,4,0,0,1-4,4h-89a4,4,0,0,1-4-4v-57A4,4,0,0,1,4.393-.368Z"
            transform="translate(1263.607 779.368)"
            fill="#bfbfbf"
          />
          <ellipse
            id="Ellipse_4"
            data-name="Ellipse 4"
            cx="5.958"
            cy="5.958"
            rx="5.958"
            ry="5.958"
            transform="translate(1306.504 805.816)"
            fill="#fff"
          />
          <rect
            id="Rectangle_123"
            data-name="Rectangle 123"
            width="97"
            height="4"
            rx="2"
            transform="translate(1264 843)"
            fill="#a7a7a7"
          />
        </g>
        <g id="Group_176" data-name="Group 176" transform="translate(161 -58)">
          <path
            id="Rectangle_39"
            data-name="Rectangle 39"
            d="M6,0h9a6,6,0,0,1,6,6v4.757a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V6A6,6,0,0,1,6,0Z"
            transform="translate(1229 835.243)"
            fill="#bfbfbf"
          />
          <path
            id="Path_121"
            data-name="Path 121"
            d="M-5003.5,804.516v3.463"
            transform="translate(6243 31.951)"
            fill="none"
            stroke="#a7a7a7"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
        <path
          id="Union_21"
          data-name="Union 21"
          d="M345.708,199.745a5,5,0,0,1-5-5V22.3H35.358V194.746a5,5,0,0,1-5,5H24.814a5,5,0,0,1-5-5V22.3H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0H372.192a5,5,0,0,1,5,5V17.3a5,5,0,0,1-5,5h-15.94V194.746a5,5,0,0,1-5,5Z"
          transform="translate(1245.999 788.657)"
          fill="#ddba7b"
        />
        <g id="Group_191" data-name="Group 191" transform="translate(114 -39)">
          <path
            id="Path_1039"
            data-name="Path 1039"
            d="M-8411,7804.356s28.1,18.655,32.138,44.023,11.29,83.27,11.29,83.27,8.518-99.225,18.2-122.808,34.911-27.945,34.911-27.945"
            transform="translate(9371.692 -6962.353)"
            fill="none"
            stroke="#7caa60"
            strokeWidth="2"
          />
          <path
            id="Path_1040"
            data-name="Path 1040"
            d="M20.595,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.595,9.725S0,9.725,0,9.725,9.221,0,20.595,0Z"
            transform="translate(922.936 832.393)"
            fill="#82aa60"
          />
          <path
            id="Path_1041"
            data-name="Path 1041"
            d="M20.595,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.595,9.725S0,9.725,0,9.725,9.221,0,20.595,0Z"
            transform="matrix(0.899, -0.438, 0.438, 0.899, 931.878, 860.391)"
            fill="#99c376"
          />
          <path
            id="Path_1042"
            data-name="Path 1042"
            d="M20.595,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.595,9.725S0,9.725,0,9.725,9.221,0,20.595,0Z"
            transform="translate(949.085 863.645) rotate(-1)"
            fill="#82aa60"
          />
          <path
            id="Path_1043"
            data-name="Path 1043"
            d="M20.6,0c11.374,0,20.6,9.725,20.6,9.725s-9.221,9.725-20.6,9.725S0,9.725,0,9.725,9.221,0,20.6,0Z"
            transform="translate(1020.089 827.234) rotate(82)"
            fill="#82aa60"
          />
          <path
            id="Path_1044"
            data-name="Path 1044"
            d="M20.595,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.595,9.725S0,9.725,0,9.725,9.221,0,20.595,0Z"
            transform="translate(1056.849 809.93) rotate(16)"
            fill="#82aa60"
          />
          <path
            id="Path_1045"
            data-name="Path 1045"
            d="M20.595,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.595,9.725S0,9.725,0,9.725,9.221,0,20.595,0Z"
            transform="translate(1031.677 824.804) rotate(16)"
            fill="#99c376"
          />
          <path
            id="Path_1046"
            data-name="Path 1046"
            d="M20.595,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.595,9.725S0,9.725,0,9.725,9.221,0,20.595,0Z"
            transform="matrix(0.985, -0.174, 0.174, 0.985, 1015.382, 859)"
            fill="#99c376"
          />
          <path
            id="Path_1047"
            data-name="Path 1047"
            d="M20.595,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.595,9.725S0,9.725,0,9.725,9.221,0,20.595,0Z"
            transform="translate(952.56 889.51) rotate(-9)"
            fill="#82aa60"
          />
          <path
            id="Path_1048"
            data-name="Path 1048"
            d="M20.595,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.595,9.725S0,9.725,0,9.725,9.221,0,20.595,0Z"
            transform="matrix(0.848, -0.53, 0.53, 0.848, 986.129, 884.829)"
            fill="#82aa60"
          />
          <path
            id="Path_1049"
            data-name="Path 1049"
            d="M20.595,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.595,9.725S0,9.725,0,9.725,9.221,0,20.595,0Z"
            transform="matrix(0.848, -0.53, 0.53, 0.848, 1005.579, 904.28)"
            fill="#82aa60"
          />
          <path
            id="Path_1050"
            data-name="Path 1050"
            d="M20.595,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.6,9.725S0,9.725,0,9.725,9.221,0,20.595,0Z"
            transform="matrix(0.616, -0.788, 0.788, 0.616, 964.378, 848.104)"
            fill="#82aa60"
          />
          <path
            id="Path_1051"
            data-name="Path 1051"
            d="M20.6,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.6,9.725S0,9.725,0,9.725,9.221,0,20.6,0Z"
            transform="matrix(0.454, -0.891, 0.891, 0.454, 980.436, 868.639)"
            fill="#99c376"
          />
          <rect
            id="Rectangle_208"
            data-name="Rectangle 208"
            width="57.812"
            height="98.537"
            rx="20"
            transform="translate(974.694 928.503)"
            fill="#eaaf90"
          />
          <path
            id="Path_1052"
            data-name="Path 1052"
            d="M20.595,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.595,9.725S0,9.725,0,9.725,9.221,0,20.595,0Z"
            transform="translate(979.758 882.895) rotate(30)"
            fill="#99c376"
          />
          <rect
            id="Rectangle_209"
            data-name="Rectangle 209"
            width="4.395"
            height="46.877"
            rx="2.197"
            transform="translate(985.137 954.807)"
            fill="#fff"
            opacity="0.5"
          />
          <path
            id="Path_1053"
            data-name="Path 1053"
            d="M20.6,0c11.374,0,20.6,9.725,20.6,9.725s-9.221,9.725-20.6,9.725S0,9.725,0,9.725,9.221,0,20.6,0Z"
            transform="translate(1029.61 792.403) rotate(77)"
            fill="#82aa60"
          />
          <path
            id="Path_1054"
            data-name="Path 1054"
            d="M20.595,0C31.969,0,41.19,9.725,41.19,9.725s-9.221,9.725-20.6,9.725S0,9.725,0,9.725,9.221,0,20.595,0Z"
            transform="matrix(0.616, -0.788, 0.788, 0.616, 1046.756, 813.458)"
            fill="#99c376"
          />
          <path
            id="Path_1055"
            data-name="Path 1055"
            d="M20.6,0c11.374,0,20.6,9.725,20.6,9.725s-9.221,9.725-20.6,9.725S0,9.725,0,9.725,9.221,0,20.6,0Z"
            transform="translate(953.548 846.928) rotate(-100)"
            fill="#99c376"
          />
        </g>
        <rect
          id="Rectangle_212"
          data-name="Rectangle 212"
          width="57.812"
          height="11.537"
          rx="5.769"
          transform="translate(1088.694 887.856)"
          fill="#ce997d"
        />
        <g id="blue_coffee" transform="translate(1007 -53)">
          <g id="blue_coffee_mug" transform="translate(-209 113)">
            <path
              id="Subtraction_4"
              data-name="Subtraction 4"
              d="M14437-5377a9.012,9.012,0,0,1-9-9,8.939,8.939,0,0,1,2.635-6.363A8.947,8.947,0,0,1,14437-5395a9.008,9.008,0,0,1,9,9A9.01,9.01,0,0,1,14437-5377Zm0-14a5.007,5.007,0,0,0-5,5,5.007,5.007,0,0,0,5,5,5,5,0,0,0,5-5A5,5,0,0,0,14437-5391Z"
              transform="translate(-13916 6095)"
              fill="#7890e2"
            />
            <path
              id="Rectangle_33"
              data-name="Rectangle 33"
              d="M2,0H28a2,2,0,0,1,2,2V25A12,12,0,0,1,18,37H12A12,12,0,0,1,0,25V2A2,2,0,0,1,2,0Z"
              transform="translate(488 692)"
              fill="#7890e2"
            />
          </g>
          <path
            id="line_of_light"
            d="M-5076.077,781.781v14.452"
            transform="translate(5363 31)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="3"
            opacity="0.5"
          />
          <path
            id="fume03"
            d="M-5076.077,781.781V788"
            transform="translate(5376 10.229)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="fume02"
            d="M-5076.077,781.781v14.452"
            transform="translate(5370 2)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="fume01"
            d="M-5076.077,781.781v9.364"
            transform="translate(5364 7.088)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
      </g>
    </svg>
  )
}

export default DevelopmentExtended
