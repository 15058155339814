import React from 'react'
import { css } from '@emotion/core'
import { steamAnimation } from '../../utils/steamAnimation'

const TrainingExtended = ({ ...rest }) => {
  return (
    <svg
      {...rest}
      css={css`
        ${steamAnimation(['fume01', 'fume02', 'fume03'])}
        ${steamAnimation(['fume01-2', 'fume02-2', 'fume03-2'], 0.15)}
        ${steamAnimation(['fume01-3', 'fume02-3', 'fume03-3'], 0.4)}
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="1083.535"
      height="265.016"
      viewBox="0 0 1083.535 265.016"
    >
      <g id="training_extended" transform="translate(-765.465 -2360.802)">
        <rect
          id="Rectangle_210"
          data-name="Rectangle 210"
          width="1083.535"
          height="68"
          rx="34"
          transform="translate(765.465 2557.817)"
          fill="#fafafc"
        />
        <path
          id="Path_910"
          data-name="Path 910"
          d="M-5076.077,781.781v14.452"
          transform="translate(6167 1617.453)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="3"
          opacity="0.5"
        />
        <path
          id="Path_914"
          data-name="Path 914"
          d="M-5076.077,781.781v14.452"
          transform="translate(6228 1617.453)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="3"
          opacity="0.5"
        />
        <path
          id="Path_918"
          data-name="Path 918"
          d="M-5076.077,781.781v14.452"
          transform="translate(6289 1617.453)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="3"
          opacity="0.5"
        />
        <g
          id="Group_204"
          data-name="Group 204"
          transform="translate(965 2360.802)"
        >
          <rect
            id="Rectangle_128"
            data-name="Rectangle 128"
            width="3"
            height="67"
            rx="1.5"
            transform="matrix(0.966, 0.259, -0.259, 0.966, 83.248, 0)"
            fill="#bfbfbf"
          />
          <rect
            id="Rectangle_127"
            data-name="Rectangle 127"
            width="70"
            height="4"
            rx="2"
            transform="translate(0 63.651)"
            fill="#a7a7a7"
          />
        </g>
        <rect
          id="Rectangle_193"
          data-name="Rectangle 193"
          width="359"
          height="13"
          rx="6.5"
          transform="translate(926 2428)"
          fill="#ddba7b"
        />
        <rect
          id="Rectangle_194"
          data-name="Rectangle 194"
          width="173.971"
          height="12.744"
          rx="6.372"
          transform="translate(926.313 2602.208) rotate(-90)"
          fill="#ddba7b"
        />
        <rect
          id="Rectangle_195"
          data-name="Rectangle 195"
          width="173.971"
          height="12.744"
          rx="6.372"
          transform="translate(1272.063 2602.208) rotate(-90)"
          fill="#ddba7b"
        />
        <g
          id="Group_185"
          data-name="Group 185"
          transform="translate(1323.958 2369.449)"
        >
          <path
            id="Union_20"
            data-name="Union 20"
            d="M117.027,104.5,95.423,2.853a2.361,2.361,0,0,1,4.62-.982l21.6,101.642a2.362,2.362,0,1,1-4.62.983ZM1.871,106.314a2.362,2.362,0,0,1-1.819-2.8L21.657,1.871a2.362,2.362,0,0,1,4.621.982L4.672,104.5a2.358,2.358,0,0,1-2.8,1.819Z"
            transform="translate(0 114.179)"
            fill="#d1d1d1"
          />
          <rect
            id="Rectangle_207"
            data-name="Rectangle 207"
            width="4.723"
            height="108.637"
            rx="2.362"
            transform="matrix(0.978, 0.208, -0.208, 0.978, 120.394, 10.771)"
            fill="#d1d1d1"
          />
          <rect
            id="Rectangle_203"
            data-name="Rectangle 203"
            width="73.684"
            height="9.311"
            rx="4.655"
            transform="matrix(-0.208, 0.978, -0.978, -0.208, 130.206, 1.936)"
            fill="#7890e2"
          />
          <rect
            id="Rectangle_202"
            data-name="Rectangle 202"
            width="96.356"
            height="9.311"
            rx="4.655"
            transform="translate(109.589 120.455) rotate(180)"
            fill="#7890e2"
          />
        </g>
        <g
          id="Group_186"
          data-name="Group 186"
          transform="translate(1483.607 2369.449)"
        >
          <path
            id="Union_20-2"
            data-name="Union 20"
            d="M117.027,104.5,95.423,2.853a2.361,2.361,0,0,1,4.62-.982l21.6,101.642a2.362,2.362,0,1,1-4.62.983ZM1.871,106.314a2.362,2.362,0,0,1-1.819-2.8L21.657,1.871a2.362,2.362,0,0,1,4.621.982L4.672,104.5a2.358,2.358,0,0,1-2.8,1.819Z"
            transform="translate(0 114.179)"
            fill="#d1d1d1"
          />
          <rect
            id="Rectangle_207-2"
            data-name="Rectangle 207"
            width="4.723"
            height="108.637"
            rx="2.362"
            transform="matrix(0.978, 0.208, -0.208, 0.978, 120.394, 10.771)"
            fill="#d1d1d1"
          />
          <rect
            id="Rectangle_203-2"
            data-name="Rectangle 203"
            width="73.684"
            height="9.311"
            rx="4.655"
            transform="matrix(-0.208, 0.978, -0.978, -0.208, 130.206, 1.936)"
            fill="#7890e2"
          />
          <rect
            id="Rectangle_202-2"
            data-name="Rectangle 202"
            width="96.356"
            height="9.311"
            rx="4.655"
            transform="translate(109.589 120.455) rotate(180)"
            fill="#7890e2"
          />
        </g>
        <g
          id="Group_187"
          data-name="Group 187"
          transform="translate(1643.256 2369.449)"
        >
          <path
            id="Union_20-3"
            data-name="Union 20"
            d="M117.027,104.5,95.423,2.853a2.361,2.361,0,0,1,4.62-.982l21.6,101.642a2.362,2.362,0,1,1-4.62.983ZM1.871,106.314a2.362,2.362,0,0,1-1.819-2.8L21.657,1.871a2.362,2.362,0,0,1,4.621.982L4.672,104.5a2.358,2.358,0,0,1-2.8,1.819Z"
            transform="translate(0 114.179)"
            fill="#d1d1d1"
          />
          <rect
            id="Rectangle_207-3"
            data-name="Rectangle 207"
            width="4.723"
            height="108.637"
            rx="2.362"
            transform="matrix(0.978, 0.208, -0.208, 0.978, 120.394, 10.771)"
            fill="#d1d1d1"
          />
          <rect
            id="Rectangle_203-3"
            data-name="Rectangle 203"
            width="73.684"
            height="9.311"
            rx="4.655"
            transform="matrix(-0.208, 0.978, -0.978, -0.208, 130.206, 1.936)"
            fill="#7890e2"
          />
          <rect
            id="Rectangle_202-3"
            data-name="Rectangle 202"
            width="96.356"
            height="9.311"
            rx="4.655"
            transform="translate(109.589 120.455) rotate(180)"
            fill="#7890e2"
          />
        </g>
        <g id="orange_coffee" transform="translate(804 1586)">
          <g id="orange_coffee_mug" transform="translate(-209 113)">
            <path
              id="Subtraction_4"
              data-name="Subtraction 4"
              d="M14437-5377a9.012,9.012,0,0,1-9-9,8.939,8.939,0,0,1,2.635-6.363A8.947,8.947,0,0,1,14437-5395a9.008,9.008,0,0,1,9,9A9.01,9.01,0,0,1,14437-5377Zm0-14a5.007,5.007,0,0,0-5,5,5.007,5.007,0,0,0,5,5,5,5,0,0,0,5-5A5,5,0,0,0,14437-5391Z"
              transform="translate(-13916 6095)"
              fill="#e8a85e"
            />
            <path
              id="Rectangle_33"
              data-name="Rectangle 33"
              d="M2,0H28a2,2,0,0,1,2,2V25A12,12,0,0,1,18,37H12A12,12,0,0,1,0,25V2A2,2,0,0,1,2,0Z"
              transform="translate(488 692)"
              fill="#e8a85e"
            />
          </g>
          <path
            id="line_of_light"
            d="M-5076.077,781.781v14.452"
            transform="translate(5363 31)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="3"
            opacity="0.5"
          />
          <path
            id="fume03"
            d="M-5076.077,781.781V788"
            transform="translate(5376 10.229)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="fume02"
            d="M-5076.077,781.781v14.452"
            transform="translate(5370 2)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="fume01"
            d="M-5076.077,781.781v9.364"
            transform="translate(5364 7.088)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
        <g
          id="orange_coffee-2"
          data-name="orange_coffee"
          transform="translate(863 1586)"
        >
          <g
            id="orange_coffee_mug-2"
            data-name="orange_coffee_mug"
            transform="translate(-209 113)"
          >
            <path
              id="Subtraction_4-2"
              data-name="Subtraction 4"
              d="M14437-5377a9.012,9.012,0,0,1-9-9,8.939,8.939,0,0,1,2.635-6.363A8.947,8.947,0,0,1,14437-5395a9.008,9.008,0,0,1,9,9A9.01,9.01,0,0,1,14437-5377Zm0-14a5.007,5.007,0,0,0-5,5,5.007,5.007,0,0,0,5,5,5,5,0,0,0,5-5A5,5,0,0,0,14437-5391Z"
              transform="translate(-13916 6095)"
              fill="#e8a85e"
            />
            <path
              id="Rectangle_33-2"
              data-name="Rectangle 33"
              d="M2,0H28a2,2,0,0,1,2,2V25A12,12,0,0,1,18,37H12A12,12,0,0,1,0,25V2A2,2,0,0,1,2,0Z"
              transform="translate(488 692)"
              fill="#e8a85e"
            />
          </g>
          <path
            id="line_of_light-2"
            data-name="line_of_light"
            d="M-5076.077,781.781v14.452"
            transform="translate(5363 31)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="3"
            opacity="0.5"
          />
          <path
            id="fume03-2"
            data-name="fume03"
            d="M-5076.077,781.781V788"
            transform="translate(5376 10.229)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="fume02-2"
            data-name="fume02"
            d="M-5076.077,781.781v14.452"
            transform="translate(5370 2)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="fume01-2"
            data-name="fume01"
            d="M-5076.077,781.781v9.364"
            transform="translate(5364 7.088)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
        <g
          id="orange_coffee-3"
          data-name="orange_coffee"
          transform="translate(922 1586)"
        >
          <g
            id="orange_coffee_mug-3"
            data-name="orange_coffee_mug"
            transform="translate(-209 113)"
          >
            <path
              id="Subtraction_4-3"
              data-name="Subtraction 4"
              d="M14437-5377a9.012,9.012,0,0,1-9-9,8.939,8.939,0,0,1,2.635-6.363A8.947,8.947,0,0,1,14437-5395a9.008,9.008,0,0,1,9,9A9.01,9.01,0,0,1,14437-5377Zm0-14a5.007,5.007,0,0,0-5,5,5.007,5.007,0,0,0,5,5,5,5,0,0,0,5-5A5,5,0,0,0,14437-5391Z"
              transform="translate(-13916 6095)"
              fill="#e8a85e"
            />
            <path
              id="Rectangle_33-3"
              data-name="Rectangle 33"
              d="M2,0H28a2,2,0,0,1,2,2V25A12,12,0,0,1,18,37H12A12,12,0,0,1,0,25V2A2,2,0,0,1,2,0Z"
              transform="translate(488 692)"
              fill="#e8a85e"
            />
          </g>
          <path
            id="line_of_light-3"
            data-name="line_of_light"
            d="M-5076.077,781.781v14.452"
            transform="translate(5363 31)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="3"
            opacity="0.5"
          />
          <path
            id="fume03-3"
            data-name="fume03"
            d="M-5076.077,781.781V788"
            transform="translate(5376 10.229)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="fume02-3"
            data-name="fume02"
            d="M-5076.077,781.781v14.452"
            transform="translate(5370 2)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="fume01-3"
            data-name="fume01"
            d="M-5076.077,781.781v9.364"
            transform="translate(5364 7.088)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
      </g>
    </svg>
  )
}

export default TrainingExtended
