import React from 'react'
import { css } from '@emotion/core'
import { DiReact, DiNodejsSmall } from 'react-icons/di'
import { MdLaptopMac, MdCloud } from 'react-icons/md'
import Fade from 'react-reveal/Fade'

import styled from '@emotion/styled'
import { injectIntl } from 'gatsby-plugin-intl'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import {
  SectionTitle,
  ServiceList,
  ServiceListItem as OriginalServiceListItem,
  GraphQL,
} from '../components/common'
import breakpoints from '../utils/breakpoints'

import DevelopmentExtended from '../components/illustrations/DevelopmentExtended'
import ConsultingExtended from '../components/illustrations/ConsultingExtended'
import TrainingExtended from '../components/illustrations/TrainingExtended'

const ServiceListItem = styled(OriginalServiceListItem)`
  padding: 0;
`

const ServiceCategory = ({
  darkBg,
  title,
  motto,
  description,
  listItems,
  reverse,
  alternate,
  illustration,
  imageRatio = 1,
  ...rest
}) => {
  return (
    <div
      css={theme => css`
        background: ${alternate ? theme.colors.white : theme.colors.fullWhite};
        width: 100vw;
      `}
      {...rest}
    >
      <Fade bottom distance="24px" duration={750}>
        <div
          css={theme => css`
            display: flex;
            padding: ${theme.margin.xLarge};
            margin: 0 auto;
            flex-wrap: wrap;
            font-size: ${theme.fontSize.small};
            flex-direction: column;
            flex-wrap: nowrap;

            ${breakpoints.md} {
              flex-direction: ${reverse ? 'row-reverse' : 'row'};
            }

            ${breakpoints.mobile} {
              padding: ${theme.margin.large} ${theme.margin.medium};
            }

            max-width: 1200px;
          `}
        >
          <div
            css={css`
              ${!reverse && 'margin-right: 50px;'}
              width: 100%;
            `}
          >
            <SectionTitle>{title}</SectionTitle>
            <div
              css={theme => css`
                margin-bottom: 24px;
                font-size: ${theme.fontSize.xSmall};
                margin-top: -20px;
              `}
            >
              {motto}
            </div>
            {description}
            <ServiceList
              dark={!darkBg}
              css={css`
                margin-top: 24px;

                ${breakpoints.mobile} {
                  margin: 24px;
                }
              `}
            >
              {listItems}
            </ServiceList>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: ${imageRatio * 100}%;
              align-self: center;
              ${reverse && 'margin-right: 50px;'}
              @media (max-width: 990px) {
                margin-right: 0;
                margin-left: 0;
                width: ${(imageRatio / 1.52 > 1 ? 1 : imageRatio / 1.52) *
                  100}%;
              }
            `}
          >
            {illustration}
          </div>
        </div>
      </Fade>
    </div>
  )
}

const ServicesPage = ({ intl }) => {
  return (
    <Layout lightFooter>
      <SEO
        title={intl.formatMessage({ id: 'services' })}
        lang={intl.locale}
        keywords={intl.formatMessage({ id: 'keywords' })}
        description={intl.formatMessage({ id: 'services_description' })}
      />
      <div
        css={theme => css`
          color: ${theme.colors.gray};
          position: relative;
          font-size: ${theme.fontSize.small};
        `}
      >
        <div
          css={theme => css`
            color: ${theme.colors.accent};
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vw;
            max-height: 200px;
            font-size: ${theme.fontSize.xxLarge};
            background: ${theme.colors.white};
            z-index: 1;
            font-weight: 100;

            ${breakpoints.mobile} {
              font-size: ${theme.fontSize.xLarge};
            }
          `}
        >
          {intl.formatMessage({ id: 'our_services' })}
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <ServiceCategory
            title={intl.formatMessage({ id: 'development' })}
            motto={intl.formatMessage({ id: 'development_motto' })}
            description={intl.formatMessage({ id: 'development_description' })}
            imageRatio={0.786 * 1.52}
            illustration={
              <DevelopmentExtended
                css={css`
                  width: 100%;
                  height: 100%;
                  margin: 50px 0;
                  max-width: 720px;
                `}
              />
            }
            listItems={
              <>
                <ServiceListItem dark>
                  {intl.formatMessage({ id: 'solution_development' })}
                </ServiceListItem>
                <ServiceListItem dark>
                  {intl.formatMessage({ id: 'web_applications' })}
                </ServiceListItem>
                <ServiceListItem dark>
                  {intl.formatMessage({ id: 'landing_pages' })}
                </ServiceListItem>
                <ServiceListItem dark>
                  {intl.formatMessage({ id: 'mobile_applications' })}
                </ServiceListItem>
                <ServiceListItem dark>
                  {intl.formatMessage({ id: 'business_logics' })}
                </ServiceListItem>
              </>
            }
          />
          <ServiceCategory
            alternate
            reverse
            title={intl.formatMessage({ id: 'consulting' })}
            motto={intl.formatMessage({ id: 'consulting_motto' })}
            description={intl.formatMessage({ id: 'consulting_description' })}
            imageRatio={0.715 * 1.52}
            illustration={
              <ConsultingExtended
                css={css`
                  width: 100%;
                  height: 100%;
                  margin: 50px 0;
                  max-width: 720px;
                `}
              />
            }
            listItems={
              <>
                <ServiceListItem dark>
                  {intl.formatMessage({ id: 'architectural_consulting' })}
                </ServiceListItem>
                <ServiceListItem dark>
                  {intl.formatMessage({ id: 'product_strategy' })}
                </ServiceListItem>
                <ServiceListItem dark>
                  {intl.formatMessage({ id: 'business_analysis' })}
                </ServiceListItem>
                <ServiceListItem dark>
                  {intl.formatMessage({ id: 'it_representation' })}
                </ServiceListItem>
                <ServiceListItem dark>
                  {intl.formatMessage({ id: 'stack_modernization' })}
                </ServiceListItem>
                <ServiceListItem dark>
                  {intl.formatMessage({ id: 'marketing' })}
                </ServiceListItem>
              </>
            }
          />
          <ServiceCategory
            title={intl.formatMessage({ id: 'training' })}
            motto={intl.formatMessage({ id: 'training_motto' })}
            description={intl.formatMessage({ id: 'training_description' })}
            imageRatio={1 + 1.52}
            illustration={
              <TrainingExtended
                css={css`
                  width: 100%;
                  height: 100%;
                  margin: 50px 0;
                  max-width: 720px;
                `}
              />
            }
            listItems={
              <>
                <ServiceListItem dark icon={DiReact}>
                  {intl.formatMessage({ id: 'front_end' })}
                </ServiceListItem>
                <ServiceListItem dark icon={DiNodejsSmall}>
                  {intl.formatMessage({ id: 'back_end' })}
                </ServiceListItem>
                <ServiceListItem dark icon={MdLaptopMac}>
                  {intl.formatMessage({ id: 'it_foundations' })}
                </ServiceListItem>
                <ServiceListItem dark icon={MdCloud}>
                  {intl.formatMessage({ id: 'cloud_journey' })}
                </ServiceListItem>
                <ServiceListItem dark icon={GraphQL}>
                  {intl.formatMessage({ id: 'graphql' })}
                </ServiceListItem>
              </>
            }
          />
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(ServicesPage)
