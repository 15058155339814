import React from 'react'
import { css } from '@emotion/core'
import { steamAnimation } from '../../utils/steamAnimation'

const ConsultingExtended = ({ ...rest }) => {
  return (
    <svg
      {...rest}
      css={css`
        ${steamAnimation(['fume01', 'fume02', 'fume03'])}
        ${steamAnimation(['fume01-2', 'fume02-2', 'fume03-2'], 0.15)}
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="774.757"
      height="345.963"
      viewBox="0 0 774.757 345.963"
    >
      <g id="consulting_extended" transform="translate(-185 -1501)">
        <rect
          id="Rectangle_190"
          data-name="Rectangle 190"
          width="774.757"
          height="68"
          rx="34"
          transform="translate(185 1778.963)"
          fill="#eaeaea"
          opacity="0.508"
        />
        <g
          id="Group_182"
          data-name="Group 182"
          transform="translate(292.646 1504.069)"
        >
          <g id="Group_183" data-name="Group 183" transform="translate(0 0)">
            <rect
              id="Rectangle_184"
              data-name="Rectangle 184"
              width="151.74"
              height="12.147"
              rx="6.074"
              transform="translate(124.151 306.883) rotate(-90)"
              fill="#949dbf"
            />
            <rect
              id="Rectangle_186"
              data-name="Rectangle 186"
              width="135.187"
              height="12.147"
              rx="6.073"
              transform="translate(0.556 197.35)"
              fill="#949dbf"
            />
            <rect
              id="Rectangle_187"
              data-name="Rectangle 187"
              width="151.74"
              height="12.147"
              rx="6.073"
              transform="translate(0.556 150.449)"
              fill="#949dbf"
            />
            <rect
              id="Rectangle_185"
              data-name="Rectangle 185"
              width="306.883"
              height="12.147"
              rx="6.074"
              transform="translate(0 306.883) rotate(-90)"
              fill="#949dbf"
            />
          </g>
          <path
            id="Rectangle_189"
            data-name="Rectangle 189"
            d="M4,0H127.049a4,4,0,0,1,4,4v7.036a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4A4,4,0,0,1,4,0Z"
            transform="translate(12.147 139.414)"
            fill="#d6d6d6"
          />
        </g>
        <path
          id="Path_871"
          data-name="Path 871"
          d="M4.393-.368h89a4,4,0,0,1,4,4v57a4,4,0,0,1-4,4h-89a4,4,0,0,1-4-4v-57A4,4,0,0,1,4.393-.368Z"
          transform="translate(488.607 1501.368)"
          fill="#bfbfbf"
        />
        <rect
          id="Rectangle_125"
          data-name="Rectangle 125"
          width="91"
          height="58"
          rx="2"
          transform="translate(492 1504)"
          fill="#ebebeb"
        />
        <circle
          id="Ellipse_59"
          data-name="Ellipse 59"
          cx="2.5"
          cy="2.5"
          r="2.5"
          transform="translate(517 1531)"
          fill="#fff"
        />
        <circle
          id="Ellipse_60"
          data-name="Ellipse 60"
          cx="2.5"
          cy="2.5"
          r="2.5"
          transform="translate(536 1531)"
          fill="#fff"
        />
        <circle
          id="Ellipse_61"
          data-name="Ellipse 61"
          cx="2.5"
          cy="2.5"
          r="2.5"
          transform="translate(555 1531)"
          fill="#fff"
        />
        <g
          id="Group_178"
          data-name="Group 178"
          transform="translate(329.125 1568.589)"
        >
          <path
            id="Union_18"
            data-name="Union 18"
            d="M34.257,238.477V11.6H5.8A5.8,5.8,0,0,1,5.8,0H423.72a5.8,5.8,0,0,1,0,11.6H397.643V58.012a6.5,6.5,0,0,1,0,3.616V235.842a5.8,5.8,0,0,1-11.6,0V66.28H45.859v172.2a5.8,5.8,0,1,1-11.6,0ZM386.042,53.36V11.6H45.859V53.36Z"
            transform="translate(0 0)"
            fill="#ddba7b"
          />
        </g>
        <g
          id="Group_181"
          data-name="Group 181"
          transform="translate(730.301 1504.068)"
        >
          <path
            id="Union_17"
            data-name="Union 17"
            d="M140.15,300.809V209.5H28.145v91.311a6.073,6.073,0,1,1-12.146,0V162.6H6.074a6.074,6.074,0,0,1,0-12.147H140.15V6.074a6.073,6.073,0,1,1,12.146,0V300.809a6.073,6.073,0,1,1-12.146,0Zm0-103.459V162.6H28.145V197.35Z"
            fill="#949dbf"
          />
          <path
            id="Rectangle_189-2"
            data-name="Rectangle 189"
            d="M4,0H127.048a4,4,0,0,1,4,4v7.036a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4A4,4,0,0,1,4,0Z"
            transform="translate(9.101 139.414)"
            fill="#d6d6d6"
          />
        </g>
        <rect
          id="Rectangle_124"
          data-name="Rectangle 124"
          width="97"
          height="4"
          rx="2"
          transform="translate(489 1565)"
          fill="#a7a7a7"
        />
        <g id="blue_coffee" transform="translate(121 727)">
          <g id="blue_coffee_mug" transform="translate(-209 113)">
            <path
              id="Subtraction_4"
              data-name="Subtraction 4"
              d="M14437-5377a9.012,9.012,0,0,1-9-9,8.939,8.939,0,0,1,2.635-6.363A8.947,8.947,0,0,1,14437-5395a9.008,9.008,0,0,1,9,9A9.01,9.01,0,0,1,14437-5377Zm0-14a5.007,5.007,0,0,0-5,5,5.007,5.007,0,0,0,5,5,5,5,0,0,0,5-5A5,5,0,0,0,14437-5391Z"
              transform="translate(-13916 6095)"
              fill="#7890e2"
            />
            <path
              id="Rectangle_33"
              data-name="Rectangle 33"
              d="M2,0H28a2,2,0,0,1,2,2V25A12,12,0,0,1,18,37H12A12,12,0,0,1,0,25V2A2,2,0,0,1,2,0Z"
              transform="translate(488 692)"
              fill="#7890e2"
            />
          </g>
          <path
            id="line_of_light"
            d="M-5076.077,781.781v14.452"
            transform="translate(5363 31)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="3"
            opacity="0.5"
          />
          <path
            id="fume03"
            d="M-5076.077,781.781V788"
            transform="translate(5376 10.229)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="fume02"
            d="M-5076.077,781.781v14.452"
            transform="translate(5370 2)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="fume01"
            d="M-5076.077,781.781v9.364"
            transform="translate(5364 7.088)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
        <g id="orange_coffee" transform="translate(403 727)">
          <g id="orange_coffee_mug" transform="translate(-209 113)">
            <path
              id="Subtraction_4-2"
              data-name="Subtraction 4"
              d="M14437-5377a9.012,9.012,0,0,1-9-9,8.939,8.939,0,0,1,2.635-6.363A8.947,8.947,0,0,1,14437-5395a9.008,9.008,0,0,1,9,9A9.01,9.01,0,0,1,14437-5377Zm0-14a5.007,5.007,0,0,0-5,5,5.007,5.007,0,0,0,5,5,5,5,0,0,0,5-5A5,5,0,0,0,14437-5391Z"
              transform="translate(-13916 6095)"
              fill="#e8a85e"
            />
            <path
              id="Rectangle_33-2"
              data-name="Rectangle 33"
              d="M2,0H28a2,2,0,0,1,2,2V25A12,12,0,0,1,18,37H12A12,12,0,0,1,0,25V2A2,2,0,0,1,2,0Z"
              transform="translate(488 692)"
              fill="#e8a85e"
            />
          </g>
          <path
            id="line_of_light-2"
            data-name="line_of_light"
            d="M-5076.077,781.781v14.452"
            transform="translate(5363 31)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="3"
            opacity="0.5"
          />
          <path
            id="fume03-2"
            data-name="fume03"
            d="M-5076.077,781.781V788"
            transform="translate(5376 10.229)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="fume02-2"
            data-name="fume02"
            d="M-5076.077,781.781v14.452"
            transform="translate(5370 2)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <path
            id="fume01-2"
            data-name="fume01"
            d="M-5076.077,781.781v9.364"
            transform="translate(5364 7.088)"
            fill="none"
            stroke="#dbdbdb"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
      </g>
    </svg>
  )
}

export default ConsultingExtended
